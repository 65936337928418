import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AlertDataContext } from "./Alert.context";
import { DataContext } from "./Data.Context";

const FunctionsContext = React.createContext({});

const FunctionsContextProvider = ({ children }) => {
    const { showInfo, showError, setLoading, setmOTP } = useContext(AlertDataContext);
    const { data, setData } = useContext(DataContext);
    const history = useHistory();

    function VerifyDetail(context) {
        return new Promise(async (resolve, reject) => {
            debugger
            var data = {
                "AccountDetails":
                {
                    //"BankingType": parseInt(context.selectedAT.id),
                    "ProductTypeId": parseInt(context.option.Id),
                    "Currency": parseInt(context.currency),
                    "AccountType": parseInt(context.type),
                    "AccountCategory": 1,
                    "Province": context.Province,
                    "City": context.City,
                    "PlaceOfBirth": context.pob,
                    "MaritalStatus": context.gender,
                    "Gender": context.gender,
                    "MotherMaidenName": context.mother,
                    "FatherHusbandName": context.father,
                    "PurposeOfAccount": context.purpose,
                    "PresentResidentialAddress": context.Residential,
                    "ExpiryDate": context.expirydate === undefined || context.expirydate === null ? new Date().toDateString() : context.expirydate,
                    "NadraIssuanceDate": context.issuancedate,
                    "ExpiryLifetime": isNaN(parseInt(context.BD_Lifetime_1)) ? 0 : parseInt(context.BD_Lifetime_1),
                    "ExpiryConcent": isNaN(parseInt(context.BD_Lifetime_2)) ? 0 : parseInt(context.BD_Lifetime_2),
                    "MobileNumberConsent": context.mobilechk === true ? true : false,
                    "latitude": context.latitude,
                    "longitude": context.longitude,
                    "UserIp": context.UserIp,
                    "RWG": context?.rwg,
                    "MinorAccount": isNaN(parseInt(context.MinorAccount_1)) ? 0 : parseInt(context.MinorAccount_1)
                },
                "baseAccountVerification":
                {
                    "CustomerName": context.customerName,
                    "CNIC": context.cnic,
                    "DateOfBirth": context.dateofbirth,
                    "PhoneNumber": "92" + context.mnumber.replace("+", ""),
                    "Email": context.email
                }
            };

            var cnicFront = new KeyValue("CNICFront", (document.getElementsByName("cnicdocfront")[0] === undefined ? null : document.getElementsByName("cnicdocfront")[0].files[0]));
            var cnicBack = new KeyValue("CNICBack", (document.getElementsByName("cnicdocback")[0] === undefined ? null : document.getElementsByName("cnicdocback")[0].files[0]));

            var CRC = new KeyValue("CRC", (document.getElementsByName("crc")[0] === undefined ? null : document.getElementsByName("crc")[0].files[0]));
            var COA = new KeyValue("COA", (document.getElementsByName("coa")[0] === undefined ? null : document.getElementsByName("coa")[0].files[0]));

            var request_data = getFileData(data, cnicFront, cnicBack, CRC, COA);
            if (new Date(context.expirydate) < new Date()) {
                var cnicreceipt = new KeyValue("CNICReceipt", document.getElementsByName("cnicreceipt")[0]?.files[0]);
                request_data = getFileData(data, cnicFront, cnicBack, cnicreceipt, CRC, COA);
            }
            var resp = await postRequest("api/DigitalAccount/AccountDetail", request_data);
            resolve({ ...resp });
        })
    }

    function FirstScreen(context, ReferralId) {
        return new Promise(async (resolve, reject) => {

            var data = {
                "AccountDetails":
                {
                    "MobileNumberConsentDetail": 0, //context.BD_Whatsapp_1 ? parseInt(context.BD_Whatsapp_1) : 0,
                },
                "baseAccountVerification":
                {
                    "CNIC": context.cnic,
                    "PhoneNumber": "92" + context.mnumber.replace("+", ""),
                    "Email": context.email
                },
                "ReferralId": ReferralId

            };

            var request_data = getFileData(data);

            var resp = await postRequest("api/DigitalAccount/VerifyDetail", request_data);
            resolve({ ...resp });
        })
    }

    function IndividualDetail(context) {
        return new Promise(async (resolve, reject) => {
            debugger
            var nutureRes = 0;
            context.NutureTrans.map(p => {
                nutureRes = nutureRes | p.value;
            })
            if (context.Branchname === undefined || context.Branchname === "Select") {
                context.Branchname = document.getElementById("Branchname").value;
            }
            var data = {
                "BranchCode": context.Branchname,
                "BranchRef": context.Branchref,
                "NatureOfTransactions": isNaN(parseInt(nutureRes)) ? 0 : parseInt(nutureRes),// context.parseInt(nutureRes),// context.,
                "EstimatedAnnualIncome": isNaN(parseInt(context.EstimatedIncome)) ? 0 : parseInt(context.EstimatedIncome),
                "ExpectedMonthlyCreditTransactions": isNaN(parseInt(context.MonthlyCredittrans)) ? 0 : parseInt(context.MonthlyCredittrans),
                "ExpectedMonthlyDebitTransactions": isNaN(parseInt(context.MonthlyDebittrans)) ? 0 : parseInt(context.MonthlyDebittrans),
                "ExpectedMonthlyCredit": isNaN(parseInt(context.MonthlyCredit)) ? 0 : parseInt(context.MonthlyCredit),
                "ExpectedMonthlyDebit": isNaN(parseInt(context.MonthlyDebit)) ? 0 : parseInt(context.MonthlyDebit),
                "MonthlyIncome": isNaN(parseInt(context.MonthlyIncome)) ? 0 : parseInt(context.MonthlyIncome)
            };


            var resp = await postRequest("api/DigitalAccount/IndividualDetail", data);
            resolve({ ...resp });

        })
    }



    function IndividualDetail_II(context) {
        return new Promise(async (resolve, reject) => {
            debugger
            var data = {
                "Nationality": context.nationality,
                "OtherNationality": context.OtherResident,
                "Education": 1,// parseInt(context.education),
                "Landline": context.landline,
                "PermanentResidentialAddress": context.PermanentResidential,

            };

            debugger
            var resp = await postRequest("api/DigitalAccount/IndividualDetailII", data);
            resolve({ ...resp });
        })
    }

    function IndividualDetail_III(context) {
        return new Promise(async (resolve, reject) => {
            debugger
            // var soruce = 0;
            // context.Income.map(p => {
            //     soruce = soruce | p.value;
            // })
            var data = {
                "Occupation": parseInt(context.Occupation),
                "OccupationCheck": context.occupationcheck === true ? true : false,
                "SourceOfIncome": context.Income,
                "NextofKinName": context.kinname,
                "NextOfKinAddress": context.kinaddress,
                "NextOfKinCNIC": context.kincnic,
                "NextOfKinRelation": context.kinrelation,
                "NextOfKinContactNumber": context.kinnumber,
                "SupporterName": context.Occupation === "4" || context.Occupation === "5" || context.Occupation === "6" || context.Occupation === "7" || context.Occupation === "12" ? context.supportername : "",
                "SupporterCNIC": context.Occupation === "4" || context.Occupation === "5" || context.Occupation === "6" || context.Occupation === "7" || context.Occupation === "12" ? context.supporterCNIC : "",
                "SupporterRelationship": context.Occupation === "4" || context.Occupation === "5" || context.Occupation === "6" || context.Occupation === "7" || context.Occupation === "12" ? context.supporterrelation : "",
                "SupporterFather": context.Occupation === "4" || context.Occupation === "5" || context.Occupation === "6" || context.Occupation === "7" || context.Occupation === "12" ? context.supporterfathername : "",
                "SupporterJob": context.Occupation === "4" || context.Occupation === "5" || context.Occupation === "6" || context.Occupation === "7" || context.Occupation === "12" ? context.supporterjob : "",
                "RemitterName": context.remittername,
                "Remitterrel": context.remitterrel,
                "OccupationCheckBox": context?.FINALIZE_Occupation_1 ? parseInt(context.FINALIZE_Occupation_1) : 0,
                "OccupationCheckBox2": isNaN(parseInt(context.Finalize_Occupation_2)) ? 0 : parseInt(context.Finalize_Occupation_2),
                "OccupationCheckBox3": isNaN(parseInt(context.Finalize_Occupation_3)) ? 0 : parseInt(context.Finalize_Occupation_3),
                "OccupationCheckBox4": isNaN(parseInt(context.Finalize_Occupation_4)) ? 0 : parseInt(context.Finalize_Occupation_4),
            };
            var incomereceipts = [];
            var incomereceiptFiles = document.getElementsByName("incomereceipt")[0] === undefined ? [] : document.getElementsByName("incomereceipt")[0].files;
            debugger;
            for (let i = 0; i < incomereceiptFiles.length; i++) {
                incomereceipts.push(new KeyValue("IncomeReceipt", incomereceiptFiles[i]));

            }

            var professionreceipts = [];
            var professionreceiptFiles = document.getElementsByName("professionreceipt")[0] === undefined ? [] : document.getElementsByName("professionreceipt")[0].files;
            debugger;
            for (let i = 0; i < professionreceiptFiles.length; i++) {
                professionreceipts.push(new KeyValue("professionReceipt", professionreceiptFiles[i]));

            }
            var fpf = document.getElementsByName("professionreceipt")[0] === undefined ? [] : document.getElementsByName("professionreceipt")[0].files
            var FPfile = new KeyValue("FPfile", null);
            if (fpf !== undefined && fpf !== null && fpf.length > 0) {
                FPfile = new KeyValue("FPfile", fpf[0]);
            }


            var request_data = getFileData(data, FPfile, ...incomereceipts, ...professionreceipts,);

            debugger
            var resp = await postRequest("api/DigitalAccount/IndividualDetailIII", request_data);
            resolve({ ...resp });
        })
    }

    function Beneficial(context) {
        return new Promise(async (resolve, reject) => {
            debugger
            var data = {
                "Name": context.BeneficialName,
                "Address": context.BeneficialAddress,
                "Mobile": "92" + context.BeneficialMobile.replace("+", ""),
                "LegalIdNo": context.BeneficialLegalID,
                "Relationship": context.BeneficialRelationship,
                "BeneficialCheck": context.BeneficialCheck
            };
            var Beneficialdoc = new KeyValue("BeneficialIdDoc", document.getElementById("Beneficialdoc").files[0]);
            var Beneficialincomedoc = new KeyValue("BeneficialIncomeDoc", document.getElementById("Beneficialincomedoc").files[0]);

            var request_data = getFileData(data, Beneficialdoc, Beneficialincomedoc);
            var resp = await postRequest("api/DigitalAccount/Beneficial", request_data);
            resolve({ ...resp });
        })
    }

    function ZakatNTrans(context) {
        return new Promise(async (resolve, reject) => {
            debugger
            var data = {
                "ZakatExemption": context.Zakat === "1" ? true : false,
                "ReasonForExemptionOfZakat": context.Zakat === "1" ? context.Zakatreason : 0,
                "FATCAStatus": context.FATCAStatus,
                "FATCADeclarations": context.FATCADeclarations !== "" ? parseInt(context.FATCADeclarations) : 0,
                "ZakatExemptionCheck": context.ZakatExemptionCheck === true ? true : false,
                "COUNTRYTAXRESIDENCE": {
                    "Country": context.taxResidence !== "" && context.taxResidence !== undefined ? parseInt(context.taxResidence) : "0",
                    "TIN": context.TIN,
                    "TINReason": context.TINReason !== "" && context.TINReason !== undefined ? parseInt(context.TINReason) : null,
                    "TINRemarks": context.TINremarks
                }

            };
            var request_data = new FormData();
            var declarationCZ = null;
            var FATCADeclarations = null;
            var declarationCZflag = false;
            var FATCADeclarationsflag = false;
            if (parseInt(context.Zakat) === 1 && parseInt(context.Zakatreason) === 1) {
                declarationCZ = new KeyValue("CZ50", document.getElementById("declarationCZ").files[0]);
                declarationCZflag = true;
            }
            // if (context.FATCAStatus !== "1") {
            //     FATCADeclarations = new KeyValue("FATCADeclarationsDoc", document.getElementById("WFile").files[0]);
            //     FATCADeclarationsflag = true;
            // }
            if (declarationCZflag) {
                request_data = getFileData(data, declarationCZ);

            } else {
                buildFormData(request_data, data);
            }
            debugger
            var resp = await postRequest("api/DigitalAccount/ZakatAndTransaction", request_data);
            resolve({ ...resp });
        })
    }

    function Remitter(context) {
        return new Promise(async (resolve, reject) => {
            debugger
            var data = {
                "Name": context.remitterName,
                "Relationship": context.remitterRelation,
                "LegalId": context.remitterlegalID,
            }
            debugger
            var resp = await postRequest("api/DigitalAccount/Remitter", data);
            resolve({ ...resp });
        })
    }


    function CNCInfo(context) {
        return new Promise(async (resolve, reject) => {
            debugger
            var atmres = 0;
            context.ATMReason.map(p => {
                atmres = atmres | p.value;
            })

            var data = {
                "ChequeBook": context.Checkbook === "1" ? true : false,
                "ChequeBookNoOfLeaves": context.Checkbook === "1" ? parseInt(context.CheckbookLeave) : 0,
                "ChequeCheck": context.ChecqueBookCheck === true ? true : false,
                "ATMDebitCard": context.ATM === "1" ? true : false,
                "EFT": context.EFT === "1" ? true : false,
                "ESS": context.ESS === "1" ? true : false,
                "ESF": context.ESS === "1" ? parseInt(context.ESF) : 0,
                "CardReason": parseInt(atmres),//context.,
                "DebitCardType": context.ATM === "1" ? parseInt(context.ATMCard.Id) : 0,
                "AgreeWithTerms": true,
                // "SignatureCheck1": parseInt(context.CNC_Signature1_1),
                // "SignatureCheck2": parseInt(context.CNC_Signature2_1),
                // "SignatureCheck3": parseInt(context.CNC_Signature3_1),
                // "SignatureCheck4": parseInt(context.CNC_Signature4_1),
                "ESFCheckbox": context.ESS === "1" ? parseInt(context.CNC_ESF_1) : 0,
                "EFTCheckbox": context.EFT === "1" ? parseInt(context.CNC_EFT_1) : 0,
                "SMSSubscription": context.smssubscription === "1" ? true : false,

            };
            var Signature = null;
            //if (context.Checkbook === "1") {
            Signature = new KeyValue("SignatureDoc", document.getElementById("signature").files[0]);
            // }

            var request_data = getFileData(data, Signature);
            var resp = await postRequest("api/DigitalAccount/ChequeAndCard", request_data);
            resolve({ ...resp });
        })
    }
    function NumberNCNICCheck(context) {
        return new Promise(async (resolve, reject) => {

            var data = {};
            var resp = await postRequest("api/DigitalAccount/CheckNumberAndCNIC", data);
            resolve({ ...resp });
        })
    }




    function ResentOtp(context) {
        debugger
        return new Promise((resolve, reject) => {
            fetch(`api/DigitalAccount/ResendOTP`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ "ReferenceId": context.cnic, "SendEmail": true })
                }
            ).then((res) => res.json().then((resp) => {
                resolve({ ...resp });
            })).catch(err => {

            })
        })
    }

    function CheckPhone(context) {
        debugger
        return new Promise((resolve, reject) => {
            fetch(`api/DigitalAccount/CheckNumber`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ "ReferenceId": "92" + context.mnumber.replace("+", "") })
                }
            ).then((res) => res.json().then((resp) => {
                resolve({ ...resp });
            })).catch(err => {

            })
        })
    }

    function VerifyOtp(context, otp, Eotp) {
        return new Promise((resolve, reject) => {
            var data = {
                "SMSOTP": otp,
                "EmailOTP": Eotp,
                "CNIC": context.cnic,
                "changeScreen": true,
                "NewEmail": ""
            };
            fetch("api/DigitalAccount/VerifyOTP",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }
            ).then((res) => res.json().then((resp) => {
                resolve({ ...resp });
            })).catch(err => {

            })
        })
    }

    function ChangeEmail(context,newEmail) {
        return new Promise((resolve, reject) => {
            var data = {
                "NewEmail": newEmail,
                "CNIC": context.cnic,
                "SMSOTP": "",
                "EmailOTP": "",
                "changeScreen": true
            };
            fetch("api/DigitalAccount/ChangeEmail",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }
            ).then((res) => res.json().then((resp) => {
                resolve({ ...resp });
            })).catch(err => {

            })
        })
    }

    function KeyValue(key, value) {
        this.key = key;
        this.value = value;
    }

    function getFileData(data, ...attachment) {
        ;
        var form_data = new FormData();
        if (!attachment) {
            let _attachment = [...arguments];
            buildFormData(form_data, data);

            _attachment.forEach((element) => {
                if (element !== null) {
                    form_data.append(element.key, element.value);
                }
            });

            return form_data;
        }

        buildFormData(form_data, data);

        attachment.forEach((element) => {
            if (element !== null) {
                form_data.append(element.key, element.value);
            }
        });

        return form_data;
    }

    function buildFormData(formData, data, parentKey) {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data == null ? '' : data;

            formData.append(parentKey, value);
        }
    }

    function postRequest(endpoint, data) {
        return new Promise((resolve, reject) => {

            var fetchObj = {};
            if (data instanceof FormData) {
                fetchObj = {
                    method: 'POST',
                    /*headers: { 'Content-Type': 'multipart/form-data' },*/
                    /*headers: { 'Content-Type': undefined},*/
                    body: data
                }
                //delete fetchObj.headers['Content-Type'];
            }
            else {
                fetchObj = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                };

            }

            fetch(endpoint,
                fetchObj
            ).then((res) => res.json().then((resp) => {

                if (resp.StatusCode === 0) {

                    resolve({ ...resp });

                }
                else if (resp.StatusCode === 2 || resp.StatusCode === 12) {
                    setmOTP("");
                    resolve({ ...resp });
                }
                else if (resp.StatusCode === 23) {
                    showError(resp.StatusMessage);
                    if (resp.ResponseObject.AccountVerificationScreen === "basicdetails") {
                        history.push("/accountoption")
                    } else {
                        history.push("/" + resp.ResponseObject.AccountVerificationScreen)
                    }
                }
                else if (resp.StatusCode === 6) {
                    showError(resp.StatusMessage);
                    resolve({ ...resp });

                }
                else {
                    showError(resp.StatusMessage);

                    resolve({ ...resp });

                }
            })).catch(err => {

                resolve(err);
            })
        })
    }
    function CheckCNIC() {
        return new Promise(async (resolve, reject) => {
            debugger
            var data = {};

            var resp = await postRequest("api/DigitalAccount/ValidatePhoneAndCNIC", data);

            resolve({ ...resp });

        })
    }

    function GetCurrentScreen() {
        return new Promise((resolve, reject) => {
            fetch("api/DigitalAccount/GetCurrentScreen").then((res) => res.json().then((resp) => {
                if (resp.StatusCode === 0) {

                    resolve({ ...resp });

                }
                else {
                    //showError("Some Error occurs. Please check your details");

                    resolve({ ...resp });

                }
            })).catch(err => {

            })
        })
    }

    function Liveness(str) {
        return new Promise(async (resolve, reject) => {
            debugger
            var data = {
                Base64Image: str
            };
            var resp = await postRequest("api/DigitalAccount/LivelinessCheck", data);
            resolve({ ...resp });

        })
    }

    function LivenessConsent(context) {
        return new Promise(async (resolve, reject) => {
            var data = {
                "PhoneCNICConsent": true,// context.PhoneCNICConsent,
                "KYCConsent": true,// context.KYCConsent,
                "CDCConsent": true,// context.CDCConsent,
                "DataConsent": true,// context.DataConsent,
                "DigitalConsent": true,// context.DigitalConsent,
                "KFSConsent": true,// context.KFSConsent,
            };
            var resp = await postRequest("api/DigitalAccount/LivenessConsent", data);
            resolve({ ...resp });

        })
    }
    function LivenessWhatsapp(str, id) {
        return new Promise(async (resolve, reject) => {
            debugger
            var data = {
                Base64Image: str,
                Id: id
            };
            var resp = await postRequest("api/DigitalAccount/LivelinessCheckWhatsapp", data);
            resolve({ ...resp });

        })
    }
    return (
        <FunctionsContext.Provider value={{
            FirstScreen,
            VerifyDetail,
            ResentOtp,
            IndividualDetail,
            IndividualDetail_II,
            IndividualDetail_III,
            VerifyOtp,
            CheckPhone,
            Beneficial,
            ZakatNTrans,
            CNCInfo,
            NumberNCNICCheck,
            Remitter,
            CheckCNIC,
            GetCurrentScreen,
            Liveness,
            LivenessConsent,
            LivenessWhatsapp,
            ChangeEmail

        }}>
            {children}
        </FunctionsContext.Provider>
    )
};

export {
    FunctionsContext,
    FunctionsContextProvider
};