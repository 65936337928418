import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import Thumbsup from '../../Assets/thumbsup.png'
import Playstore from '../../Assets/playstore.webp'
import Appstore from '../../Assets/appstore.jpg'

import { useHistory, useLocation } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Recaptcha from 'react-recaptcha'
import Animatedpage from "../animatepage";
import Confetti from "../customComponents/Confetti";

const Thankyou = ({ route }) => {
    const history = useHistory();
    const { setActive, setComplete } = useContext(StepsContext);
    const { data } = useContext(DataContext);
    const d = new Date();
    const location = useLocation();
    const [show, setShow] = useState(true)
    useEffect(() => {
        setActive("Form Submit");
        localStorage.removeItem("dataCon")
        localStorage.removeItem("ExpiryTime")
        setTimeout(() => {
            setShow(false)
        }, 1000 * 20);
    }, [])
    return (
        <Animatedpage>
            <Grid item xs={12} id="s1q2" style={{ marginTop: "5vh", backgroundColor: "white" }}>
                <Row>
                    <>
                        <div style={{ alignContent: "center", textAlign: "center" }}>
                            <img src={Thumbsup} className="thumpups" style={{ height: 100, width: 100, marginBottom: 15 }} />
                            <h1 className="Q">Dear Customer, your Account opening request has been
                                successfully received by BankIslami. Your Account will be
                                activated within two working days after complete verification of
                                your provided information Insha’Allah.</h1>
                            <p className="Q" style={{marginTop:15}}>Your account reference number is {location.state.Ref}.</p>
                            <p className="Q" style={{ marginTop: 15 }}>For uninterrupted services, please download BankIslami Touchless Biometric App now and get your requested Account verified by NADRA within 60 days.</p>
                            <a href="https://play.google.com/store/apps/details?id=com.bankislami.touchlessbiometric" target="_blank">
                                <img src={Playstore} style={{ width: 200, marginTop: 20, marginRight: 10 }} />
                            </a>
                            <a href="https://apps.apple.com/us/app/bankislami-touchless-biometric/id1640468200" target="_blank">
                            <img src={Appstore} style={{width:200,marginTop:20}}/>
                            </a>
                            
                            
                        </div>
                        
                    </>
                    {show ?
                        <Confetti />
                        : <></>}
                </Row>
            </Grid>
        </Animatedpage>
    )

}
export default Thankyou;