import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Recaptcha from 'react-recaptcha'
import Animatedpage from "../animatepage";
import camera from "../customComponents/Camera";
import { AlertDataContext } from "../Context/Alert.context";
// import Islami  from '../../Assets/Documents/ProductTypeDoc/Islami Asaan Sahulat.pdf'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Config } from "../congig";
import Modal from '@material-ui/core/Modal';

const TNC = () => {
    const { setActive, setComplete } = useContext(StepsContext);
    const { data, setvalue } = useContext(DataContext);
    const { DynamicModelShow, setLoading } = useContext(AlertDataContext)
    const [accept, setAccept] = useState(false)
    const [countdown, setcountdown] = useState(60);
    const history = useHistory();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [model, setModel] = useState(false)
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);

    }
    const showFDAModel = () => {
        setModel(true);
    }
    useEffect(() => {
        // camera.startCamera();
        setActive("Verification");
        setLoading(true);
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        let nv = urlParams.get('nv');
        if (nv === "true") {
            let to = urlParams.get('to');
            history.replace(to);
        }


        setLoading(false);
        return () => {

        }
    }, [])
    const checkotp = (event) => {
        // event.preventDefault();
        if (accept) {
            history.push("liveness")

        } else {
            setModel(true);
        }

    }
    return (
        <Animatedpage submit={checkotp} useauth={true}>
            <Grid item xs={12} id="s1q2">
                <Row>
                    <label for="otpchk" className="" style={{ marginBottom: 20 }}>
                        <input type="checkbox" id="otpchk" name="otpchk" checked={accept} onClick={() => { setPageNumber(1); showFDAModel(); }} style={{ marginRight: 5, width: 18, height: 18 }} />
                        I acknowledge the Product Features, Key Fact Statement and Accept the Terms & Conditions.
                    </label >
                    <div className="btndiv">
                        <button style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => history.goBack()}>
                            <ArrowLeft style={{ marginLeft: -10 }} />
                            Back
                        </button>
                        <button type="submit" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>Next
                            <ArrowRight style={{ marginRight: -10 }} />
                        </button>
                    </div>
                </Row>
            </Grid>
            {model
                ?
                <div style={{ position: "absolute", bottom: 10, left: 20 }} >
                    <Modal
                        disablePortal
                        disableEnforceFocus
                        disableAutoFocus
                        open
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                    >
                        <div className="DynamicModelcls" style={{ width: "50vw", height: "fit-content", background: "white", alignSelf: "center", margin: "auto", marginTop: "10vh", borderRadius: "20px", padding: 20 }}>
                            <div className="dymodelbody">
                                <div style={{ height: "75vh", overflow: "auto" }}>
                                    <p className="Q" style={{ margin: 10 }}>Product Features, T&C And KFS</p>
                                    <Document
                                        file={(Config.LOCALIMG ? Config.LOCALADDRESS : window.location.origin) + `/ProductTypeDoc/${data?.currency === "1" || data?.currency === 1 ? data?.option?.Id : data?.option?.Id + "-2"}.pdf`}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        <Page pageNumber={pageNumber} />
                                    </Document>
                                    <button variant="contained" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                                        onClick={() => { setPageNumber(pageNumber > 1 ? pageNumber - 1 : 1) }}
                                    >Previous Page
                                    </button>
                                    <button variant="contained" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                                        onClick={() => { setPageNumber(pageNumber + 1) }}
                                    >Next Page
                                    </button>
                                    <button variant="contained" style={{ margin: 10, background: "var(--light)", border: "none", borderRadius: 10, marginTop: 20 }}
                                        onClick={() => { setModel(false); setAccept(true) }}
                                    >I Accept
                                    </button>

                                    <button variant="contained" style={{ margin: 10, background: "grey", border: "none", borderRadius: 10, marginTop: 20 }}
                                        onClick={() => setModel(false)}
                                    >Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
                : <></>
            }

        </Animatedpage>
    )

}
export default TNC;