import React, { Component } from 'react';
import { Config } from './components/congig';
import ClientSide from './components/ClientSide';
export default class App extends Component {
  static displayName = App.name;
  state = {
    loading: true
  }
  async componentDidMount() {
    window.onclose = () => {
      window.localStorage.clear();
    }
    var t = await this.getconfig();
    if (t.StatusCode === 0) {

      Config.ADMIN = t.ResponseObject.IsAdmin;
      Config.LOCALIMG = t.ResponseObject.IsLocaling;
      Config.robot = t.ResponseObject.IsRobotOn;
      Config.LOCALADDRESS = t.ResponseObject.LocalAddress;
      Config.localstorageVersion = t.ResponseObject.LocalStorageVersion;
    }
    
    this.setState({ loading: false });

  }
  componentWillMount() {
    if (Config.ADMIN === true) {
      require('./admin.css');
    }
  }
  getconfig() {
    return new Promise((resolve, reject) => {
      fetch("api/DigitalAccountApi/GetProjectConfiguration").then((res) => res.json().then((resp) => {
        resolve(resp);
      })).catch(err => {
        
      })
    })
  }

  render() {
    return (
      <ClientSide />
    );
  }
}
