import React, { useContext, useEffect, useState, useRef } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft } from "@material-ui/icons";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import { AlertDataContext } from "../Context/Alert.context";
import { FunctionsContext } from "../Context/Functions.context";
import Check from '../../Assets/ready.svg';
import requestOnLivness from "fas-web-ui-components/requestModule";

const BasicDetails = () => {
    const { setActive, setComplete } = useContext(StepsContext);
    const { setLoading, showError } = useContext(AlertDataContext);
    const { LivenessConsent, Liveness } = useContext(FunctionsContext);
    const [countdown, setCountdown] = useState(60);
    const history = useHistory();
    const { data } = useContext(DataContext);
    const [selfie, setSelfie] = useState(false);
    const [text, setText] = useState("");
    const [obj, setObj] = useState({});
    const [base64img, setBase64img] = useState("");
    const camera = useRef(null);

    

    useEffect(() => {
        const handlePermission = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                stream.getTracks().forEach(track => track.stop()); // Close the stream immediately
                openCamera();
            } catch (err) {
                showError("Camera permission denied. Please allow camera access to proceed.");
            }
        };

        handlePermission();

        const currentCamera = camera.current;

        if (currentCamera) {
            currentCamera.addEventListener("capture", handleCapture);
        }

        return () => {
            if (currentCamera) {
                currentCamera.removeEventListener("capture", handleCapture);
            }
        };
    }, []);

    const openCamera = () => {
        if (camera.current) {
            let event = new Event("openCamera");
            camera.current.dispatchEvent(event);
        }
    };

    const handleCapture = async event => {
        const photos = event.detail.photos;
        setText("");
        var base64 = await blobToBase64(photos[0]);

        setLoading(true);
        var t = await Liveness(base64);
        if (t.StatusCode === 0) {
            setText(t.ResponseObject.error);
            setObj(t.ResponseObject);
            if (parseFloat(t.ResponseObject.probability).toFixed(2) > 0.6) {
                setSelfie(true);
                setBase64img(base64);
            } else {
                setSelfie(false);
            }
        }
        setLoading(false);
        closeCamera();
    };

    const closeCamera = () => {
        if (camera.current) {
            let es = new Event("closeCamera");
            camera.current.dispatchEvent(es);
        }
    };

    function blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    const checkOtp = async (event) => {
        event.preventDefault();
        if (!selfie) {
            showError("Liveness Test is required");
            return;
        }
        const a = await LivenessConsent(data);
        if (a.StatusCode === 0) {
            history.push("cnicverification");
        }
    };

    return (
        <Animatedpage submit={checkOtp}>
            <Grid item xs={12} id="s1q2">
                <Row>
                    <h3 className="Q">Photo requirements</h3>
                    <h3 className="Q">Your photos must be:</h3>
                    <ul style={{ marginBottom: 10 }}>
                        <li>Clear, sharp and in focus</li>
                        <li>Taken with a neutral facial expression</li>
                        <li>Eyes open and clearly visible</li>
                        <li>Mouth closed, neutral expression</li>
                        <li>In case of minor account request, live photo of minor will be required.</li>
                    </ul>
                    <h3 className="Q">Place your face in the frame and look towards the camera. Your photo will be captured automatically.</h3>
                    {!selfie && (
                        <camera-component
                            url_logo="../blue.png"
                            is_multiframe={"false"}
                            disable_control_panel={true}
                            timer={false}
                            show_mask={"true"}
                            background_color="#f7f8fa"
                            ref={camera}
                            face_detection={true}
                            model_path="/models/"
                        />
                    )}
                    {text !== "" && !selfie ? (
                        <table style={{ display: "inline-block" }}>
                            <thead>
                                <tr>
                                    <th>Probability</th>
                                    <th>Quality</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{parseFloat(obj.probability).toFixed(2)}</td>
                                    <td>{parseFloat(obj.quality).toFixed(2)}</td>
                                    <td>{parseFloat(obj.score).toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </table>
                    ) : null}
                    {selfie ? (
                        <>
                            <br />
                            <img src={base64img} style={{ width: 200, height: 150 }} alt="Captured" />
                            <br />
                            <img src={Check} style={{ width: 50 }} alt="Check" />
                            <label className="Q">Liveness Test Successful</label>
                            <br />
                        </>
                    ) : (
                        <>
                            {text !== "" ? (
                                <>
                                    <h3 className="Q" style={{ color: "red" }}>Please try again.</h3>
                                    <h3 className="Q" style={{ color: "red" }}>Note: {text}</h3>
                                    <button
                                        type="button"
                                        style={{ margin: 10, background: "var(--light)", border: "none", borderRadius: 10, marginTop: 20 }}
                                        onClick={openCamera}
                                    >
                                        Try Again
                                    </button>
                                    <button
                                        type="button"
                                        style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                                        onClick={() => { setSelfie(false); let event = new Event("takePhoto"); camera.current.dispatchEvent(event) }}
                                    >
                                        Capture
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button
                                        type="button"
                                        style={{ margin: 10, background: "var(--light)", border: "none", borderRadius: 10, marginTop: 20 }}
                                        onClick={openCamera}
                                    >
                                        Open Camera
                                    </button>
                                    <button
                                        type="button"
                                        style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                                        onClick={() => { setSelfie(false); let event = new Event("takePhoto"); camera.current.dispatchEvent(event) }}
                                    >
                                        Capture
                                    </button>
                                </>
                            )}
                        </>
                    )}
                    <div className="btndiv">
                        <button
                            style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }}
                            onClick={() => history.goBack()}
                        >
                            <ArrowLeft style={{ marginLeft: -10 }} />
                            Back
                        </button>
                        <button
                            type="submit"
                            style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                        >
                            Next
                            <ArrowRight style={{ marginRight: -10 }} />
                        </button>
                    </div>
                </Row>
            </Grid>
        </Animatedpage>
    );
};

export default BasicDetails;
