import React, { useContext, useEffect, useState, useRef } from "react";
import { Grid, Button, Typography, List, ListItem, ListItemText, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import { AlertDataContext } from "../Context/Alert.context";
import { FunctionsContext } from "../Context/Functions.context";
import CheckIcon from '../../Assets/ready.svg';
import requestOnLivness from "fas-web-ui-components/requestModule";

const BasicDetails = () => {
    const { setActive, setComplete } = useContext(StepsContext);
    const { setLoading, showError } = useContext(AlertDataContext);
    const { LivenessConsent, LivenessWhatsapp } = useContext(FunctionsContext);
    const [selfie, setSelfie] = useState(false);
    const [statusText, setStatusText] = useState("");
    const [livenessData, setLivenessData] = useState({});
    const [base64Image, setBase64Image] = useState("");
    const history = useHistory();
    const { data, setData, setValue } = useContext(DataContext);
    const cameraRef = useRef(null);

    useEffect(() => {
        const checkCameraPermissions = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                if (!stream) {
                    showError("Camera not detected. Please ensure your camera is connected and try again.");
                    return;
                }
                openCamera();
            } catch (error) {
                showError("Camera permission denied. Please allow camera access and try again.");
            }
        };

        const openCamera = () => {
            const event = new Event("openCamera");
            cameraRef.current.dispatchEvent(event);
            cameraRef.current.addEventListener("capture", handleCapture);
        };

        const queryParams = new URLSearchParams(window.location.search);
        const applyById = queryParams.get("applyby");

        if (!applyById) {
            history.push("/");
        } else {
            checkCameraPermissions();
        }

        return () => {
            if (cameraRef.current) {
                cameraRef.current.removeEventListener("capture", handleCapture);
            }
        };
    }, [history, showError]);

    const handleCapture = async (event) => {
        const photos = event.detail.photos;
        setStatusText("");
        setLoading(true);

        try {
            const base64 = await convertBlobToBase64(photos[0]);
            const queryParams = new URLSearchParams(window.location.search);
            const applyById = queryParams.get("applyby");

            const response = await LivenessWhatsapp(base64, applyById);
            if (response.StatusCode === 0) {
                setStatusText(response.ResponseObject.error);
                setLivenessData(response.ResponseObject);
                const probability = parseFloat(response.ResponseObject.probability).toFixed(2);

                if (probability > 0.6) {
                    setSelfie(true);
                    setBase64Image(base64);
                } else {
                    setSelfie(false);
                }
            }
        } catch (error) {
            showError("An error occurred during the liveness test. Please try again.");
        }
    };

    const convertBlobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };

    const handleOtpCheck = async (event) => {
        event.preventDefault();

        if (!selfie) {
            showError("Liveness Test is required.");
            return;
        }

        try {
            const response = await LivenessConsent(data);
            if (response.StatusCode === 0) {
                history.push("cnicverification");
            } else {
                showError("Consent verification failed. Please try again.");
            }
        } catch (error) {
            showError("An error occurred during consent verification. Please try again.");
        }
    };

    return (
        <Animatedpage submit={handleOtpCheck}>
            <Grid item xs={12} id="s1q2">
                <Row>
                    <Typography variant="h5" className="Q">Photo Requirements</Typography>
                    <Typography variant="subtitle1" className="Q">Your photos must be:</Typography>
                    <List>
                        <ListItem>
                            <ListItemText primary="Clear, sharp and in focus" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Taken with a neutral facial expression" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Eyes open and clearly visible" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Mouth closed, neutral expression" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="In case of minor account request, live photo of minor will be required." />
                        </ListItem>
                    </List>
                    <Typography variant="subtitle1" className="Q">
                        Place your face in the frame and look towards the camera. Your photo will be captured automatically.
                    </Typography>
                    {!selfie ? (
                        <camera-component
                            url_logo="../blue.png"
                            is_multiframe="false"
                            disable_control_panel={true}
                            timer={false}
                            show_mask="true"
                            background_color="#f7f8fa"
                            ref={cameraRef}
                            face_detection={true}
                            model_path="/models/"
                        />
                    ) : null}
                    {statusText && !selfie ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Probability</TableCell>
                                    <TableCell>Quality</TableCell>
                                    <TableCell>Score</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{parseFloat(livenessData.probability).toFixed(2)}</TableCell>
                                    <TableCell>{parseFloat(livenessData.quality).toFixed(2)}</TableCell>
                                    <TableCell>{parseFloat(livenessData.score).toFixed(2)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    ) : null}
                    {selfie ? (
                        <>
                            <img src={base64Image} alt="Captured Selfie" style={{ width: 200, height: 150 }} />
                            <img src={CheckIcon} alt="Check Icon" style={{ width: 50 }} />
                            <Typography className="Q">Liveness Test Successful</Typography>
                            <Typography>Your liveliness test has been successful. Please continue your journey on WhatsApp.</Typography>
                        </>
                    ) : (
                        <>
                            {statusText ? (
                                <>
                                    <Typography variant="h6" className="Q" style={{ color: "red" }}>
                                        Please try again.
                                    </Typography>
                                    <Typography variant="h6" className="Q" style={{ color: "red" }}>
                                        Note: {statusText}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        style={{ margin: 10, marginTop: 20 }}
                                        onClick={() => {
                                            const event = new Event("openCamera");
                                            cameraRef.current.dispatchEvent(event);
                                        }}
                                    >
                                        Try Again
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ margin: 10, marginTop: 20 }}
                                        onClick={() => {
                                            setSelfie(false);
                                            const event = new Event("takePhoto");
                                            cameraRef.current.dispatchEvent(event);
                                        }}
                                    >
                                        Capture
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        style={{ margin: 10, marginTop: 20 }}
                                        onClick={() => {
                                            const event = new Event("openCamera");
                                            cameraRef.current.dispatchEvent(event);
                                        }}
                                    >
                                        Open Camera
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ margin: 10, marginTop: 20 }}
                                        onClick={() => {
                                            setSelfie(false);
                                            const event = new Event("takePhoto");
                                            cameraRef.current.dispatchEvent(event);
                                        }}
                                    >
                                        Capture
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                </Row>
            </Grid>
        </Animatedpage>
    );
};

export default BasicDetails;
