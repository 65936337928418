import React, { useContext, useEffect, useRef, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import { Chip, MenuItem } from "@material-ui/core";

import Select from 'react-select';
import Input from "reactstrap/lib/Input";
import { makeStyles } from "@material-ui/styles";
// import { Branches } from "../../Assets/branches"
import { FunctionsContext } from "../Context/Functions.context";
import { AlertDataContext } from "../Context/Alert.context";
import { ApiDataContext } from "../Context/Api.context";
import { CheckNumeric } from "../service";
import { screen } from "./screen";
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: 1,
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: 3,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const reasons = [
    "Cash Deposit/Withdrawal"
    , "Foreign Remittances"
    , "Fund Transfers Own/Other Bank"
    , "Inward/Outward Clearing"
]

const Cardreasons = [
    "Brand Conscious"
    , "e-Commerce Transactions"
    , "Foreign Travelling"
    , "Offers attraction"
]
const IndividualInformation = () => {
    const { data, setData, setvalue, setmultiple } = useContext(DataContext);
    const { setActive, setComplete } = useContext(StepsContext);
    const { showInfo, showError, setLoading, setmOTP } = useContext(AlertDataContext);
    const { ApiData, setApiData } = useContext(ApiDataContext)
    const { IndividualDetail } = useContext(FunctionsContext)
    const cityref = useRef();
    const [show, setshow] = useState(false);
    const [plist, setPlist] = useState([]);
    const [brancheslist, setbrancheslist] = useState([])
    const history = useHistory();
    const classes = useStyles();
    useEffect(() => {
        setActive("Individual Information");
    }, [])
    const populatecities = (list) => {
        var cities = {};
        var t = "<option>Select</option>";
        brancheslist.map(p => {
            if (p.Province === data.Branchprovince && !(p.City in cities)) {
                t += `<option value="${p.City}">${p.City}</option>`;
                cities[p.City] = 1;
            }
        })
        cityref.current.innerHTML = t;
    }
    useEffect(() => {
        if (plist.length === 0) {
            popbanklist();
        }
    }, [ApiData])
    const popbanklist = () => {
        debugger
        setLoading(true);
        if (ApiData.BankList?.length > 0) {
            var t = ApiData.BankList;

            if (t?.length > 0) {
                var arr = [];
                var alreadyp = [];
                var branches = [];
                for (let index = 0; index < t.length; index++) {

                    const element = JSON.parse(t[index]);

                    if (alreadyp[element.Province] === undefined) {
                        console.log(element.Province);
                        console.log("push");
                        arr.push(element.Province);
                        alreadyp[element.Province] = 1;
                    }
                    branches.push(element);
                }
                setPlist([...arr]);
                setbrancheslist([...branches]);
            }
        }
        setLoading(true);

    }
    const enable = screen[history.location.pathname.replace("/", "")] < screen[sessionStorage.getItem("CurrentScreen")] ? true : false;
    const datasubmit = async (e) => {
        // e.preventDefault();
        setLoading(true);
        var resp = await IndividualDetail(data);
        setLoading(false);
        if (resp.StatusCode === 0) {
            setApiData({ ...ApiData, Country: [...resp.ResponseObject.Country], Education: [...resp.ResponseObject.Education] });
            history.push("znt")
        }
    }
    const populatemultiopt = (arr) => {
        var s = [];
        arr.map(p => {
            s.push({ value: p.id, label: p.Name });
        })
        return s;
    }
    return (
        <Animatedpage submit={() => { !enable ? datasubmit() : history.push("znt") }} pop={() => { setshow(true); }}>
            {show ?
                <Grid item xs={12} id="s1q2">
                    <Row>
                        <div>
                            {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /2</p> */}
                            <h1 className="Q">Selection of Preferred Branch</h1>
                            <h1 className="Q" style={{ fontSize: 12 }}>Province</h1>
                            <select className="inputtxt" name="Branchprovince" onChange={(txt) => { setvalue(txt); populatecities() }} required>
                                <option>Select</option>
                                {/*<option value="Punjab">Punjab</option>*/}
                                {/*<option value="khyber pakhtunkhwa">KPK</option>*/}
                                {/*<option value="Sindh">Sindh</option>*/}
                                {/*<option value="Balochistan">Balochistan</option>*/}
                                {plist.map(p =>
                                    <option value={p}>{p}</option>
                                )}
                            </select>

                            <h1 className="Q" style={{ fontSize: 12 }}>City</h1>
                            <select className="inputtxt" name="Branchcity" onChange={(txt) => { setvalue(txt) }} ref={cityref} required>

                            </select>
                            <h1 className="Q" style={{ fontSize: 12 }}>Branch</h1>
                            <select className="inputtxt" name="Branchname" id="Branchname" onChange={(txt) => setvalue(txt)} required>
                                {/* <option>Select</option> */}
                                {/* <option>Clifton Branch 0054</option> */}
                                {brancheslist.map(p => (
                                    <>
                                        {
                                            p.City === data.Branchcity ?
                                                <option value={p["Branch Code"]}>{p["Branch Code"] + "-" + p["Branch Name"]}</option>
                                                : <></>
                                        }
                                    </>
                                ))}
                            </select>
                            <h1 className="Q" style={{ fontSize: 12 }}>Branch Staff Referral</h1>
                            <input className="inputtxt" name="Branchref" value={ApiData?.ReferralEmployeeCode !== "" && ApiData?.ReferralEmployeeCode !== null ? ApiData.ReferralEmployeeCode : data.Branchref} onChange={(txt) => CheckNumeric(txt, () => { setvalue(txt) }, () => { })}
                            //disabled={ApiData?.ReferralEmployeeCode !== "" && ApiData?.ReferralEmployeeCode !== null ? true : false} 
                            />
                            <p className="Qhelper2"></p>
                        </div>
                        {data?.option?.Id !== 1 && data?.option?.Id !== 2 && data?.option?.Id !== 3 && data?.option?.Id !== 16 && data?.option?.Id !== 17 && data?.option?.Id !== 18?
                            <>
                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                                    <h1 className="Q" style={{ marginTop: 20 }}>Nature of Transactions</h1>

                                    <Select
                                        defaultValue={data.NutureTrans}
                                        isMulti
                                        name="colors"
                                        options={populatemultiopt(ApiData.NatureOfTransactionsList)}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={(txt) => setmultiple(txt, "NutureTrans")}
                                    />
                                    <p className="Qhelper2"></p>

                                </div>


                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                                    <h1 className="Q">Estimated Annual Income - {ApiData.currencyList.filter(p => p.Name === data.currency)[0]?.Name}</h1>
                                    <select className="inputtxt" name="EstimatedIncome" onChange={(txt) => setvalue(txt)} required
                                    //disabled={data.currency==="PKR"&&data.type==="current"?true:false}
                                    >
                                        <option value="">Select</option>
                                        {ApiData.ExpectedMonthlyList.map(p => (
                                            <option value={p.id} selected={parseInt(data.EstimatedIncome) === p.id ? true : false}>{p.Name}</option>
                                        ))}
                                    </select>
                                    <p className="Qhelper2"></p>

                                </div>
                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                                    <h1 className="Q">Expected Monthly Credit – Transactions</h1>
                                    <select className="inputtxt" name="MonthlyCredittrans" onChange={(txt) => setvalue(txt)} required
                                    //disabled={data.currency==="PKR"&&data.type==="current"?true:false}
                                    >
                                        <option value="">Select</option>

                                        {ApiData.TransactionCountList.map(p => (
                                            <option value={p.id} selected={parseInt(data.MonthlyCredittrans) === p.id ? true : false}>{p.Name}</option>
                                        ))}
                                    </select>
                                    <p className="Qhelper2"></p>

                                </div>
                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                                    <h1 className="Q">Expected Monthly Debit – Transactions</h1>
                                    <select className="inputtxt" name="MonthlyDebittrans" onChange={(txt) => setvalue(txt)} required
                                    //disabled={data.currency==="PKR"&&data.type==="current"?true:false}
                                    >
                                        <option value="">Select</option>

                                        {ApiData.TransactionCountList.map(p => (
                                            <option value={p.id} selected={parseInt(data.MonthlyDebittrans) === p.id ? true : false}>{p.Name}</option>
                                        ))}
                                    </select>
                                    <p className="Qhelper2"></p>

                                </div>

                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                                    <h1 className="Q">Expected Monthly Credit Turnover - {ApiData.currencyList.filter(p => p.Name === data.currency)[0]?.Name}</h1>
                                    <select className="inputtxt" name="MonthlyCredit" onChange={(txt) => setvalue(txt)} required
                                    //disabled={data.currency==="PKR"&&data.type==="current"?true:false}
                                    >
                                        <option value="">Select</option>

                                        {ApiData.ExpectedMonthlyList.map(p => (
                                            <option value={p.id} selected={parseInt(data.MonthlyCredit) === p.id ? true : false}>{p.Name}</option>
                                        ))}
                                    </select>
                                    <p className="Qhelper2"></p>

                                </div>
                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                                    <h1 className="Q">Expected Monthly Debit Turnover - {ApiData.currencyList.filter(p => p.Name === data.currency)[0]?.Name}</h1>
                                    <select className="inputtxt" name="MonthlyDebit" onChange={(txt) => setvalue(txt)} required
                                    //disabled={data.currency==="PKR"&&data.type==="current"?true:false}
                                    >
                                        <option value="">Select</option>

                                        {ApiData.ExpectedMonthlyList.map(p => (
                                            <option value={p.id} selected={parseInt(data.MonthlyDebit) === p.id ? true : false}>{p.Name}</option>
                                        ))}
                                    </select>
                                    <p className="Qhelper2"></p>

                                </div>

                            </> : <></>}

                        <div>
                            {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                            <h1 className="Q">Monthly Income</h1>
                            <input className="inputtxt" name="MonthlyIncome" onChange={(txt) => CheckNumeric(txt, () => { setvalue(txt) }, () => { })} required
                            //disabled={data.currency==="PKR"&&data.type==="current"?true:false}
                            />
                            <p className="Qhelper2"></p>

                        </div>
                        <div className="btndiv">
                            <Button variant="contained" style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => history.push("finalize")}>
                                <ArrowLeft style={{ marginLeft: -10 }} />
                                Back
                            </Button>
                            <Button variant="contained" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }} >Next
                                <ArrowRight style={{ marginRight: -10 }} />
                            </Button>
                        </div>
                    </Row>
                </Grid>
                :
                <></>
            }
        </Animatedpage>
    )

}
export default IndividualInformation;