import React, { useContext, useEffect, useState, useRef } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import { ContineuResentOtp, VerifyContinueOTP, ResentOtp } from "../service";
import { AlertDataContext } from "../Context/Alert.context";
import { ApiDataContext } from "../Context/Api.context";

const BasicDetails = () => {
    const { data, Bindata } = useContext(DataContext);
    const { setActive } = useContext(StepsContext);
    const { showError, setLoading } = useContext(AlertDataContext);
    const { ApiData } = useContext(ApiDataContext);

    const [otp, setotp] = useState("");
    const [countdown, setCountdown] = useState(120); // Countdown in seconds
    const [mnumber, setmnumber] = useState("");
    const [email, setemail] = useState("");
    const history = useHistory();

    const intervalRef = useRef(null); // To store the interval reference

    // Handle mounting
    useEffect(() => {
        setActive("Basic Details & OTP Verification");
        setLoading(true);

        async function fetchOtpData() {
            if (history.location.state?.phone) {
                setmnumber(history.location.state.phone);
                setemail(history.location.state.email);
            }

            try {
                await ContineuResentOtp(history.location.state?.cnic);
                startCountdown(180); // Start with 3 minutes (180 seconds)
            } catch (error) {
                showError("Error fetching OTP data.");
            } finally {
                setLoading(false);
            }
        }

        fetchOtpData();

        // Clean up interval on unmount
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    // Countdown timer function
    const startCountdown = (timeInSeconds) => {
        setCountdown(timeInSeconds);

        intervalRef.current = setInterval(() => {
            setCountdown((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(intervalRef.current);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
    };

    // Resend OTP and restart countdown
    const resend = async () => {
        setLoading(true);
        try {
            await ResentOtp(data);
            startCountdown(180);  // Restart countdown after resending OTP
        } catch (error) {
            showError("Error resending OTP.");
        } finally {
            setLoading(false);
        }
    };

    const datasubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const resp = await VerifyContinueOTP(data.cnic, otp);

            if (resp.StatusCode === 0) {
                await Bindata(resp.ResponseObject);
                const nextScreen = resp.ResponseObject.currentScreen === "basicdetails" ? "/accountoption" : `/tnc?nv=true&to=${resp.ResponseObject.currentScreen}`;
                history.push(nextScreen);
            } else {
                showError(resp.StatusMessage);
            }
        } catch (error) {
            showError("Error submitting OTP.");
        } finally {
            setLoading(false);
        }
    };

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
    };

    return (
        <Animatedpage submit={datasubmit} useauth={true}>
            <Grid item xs={12} id="s1q2">
                <Row>
                    <div>
                        <p className="Qhelper">Email : <b>{email}</b></p>
                    </div>

                    <div>
                        <p className="Qhelper">Enter the OTP code sent to your Email Address.</p>
                        <input className="inputtxt" name="otp" type={"password"} minLength={6} maxLength={6} onChange={(e) => setotp(e.target.value)} required />
                        <div id="element" style={{ margin: 20 }}>
                            {countdown > 0 ? (
                                `OTP expire in ${formatTime(countdown)}`
                            ) : (
                                <p style={{textAlign: "center",width:"200px", backgroundColor: "var(--secondary)", color: "white", padding: "10px", cursor: "pointer"}} onClick={resend}>
                                    Resend OTP
                                </p>
                            )}
                        </div>
                    </div>

                    <label htmlFor="otpchk" style={{ marginBottom: 20 }}>
                        <input type="checkbox" id="otpchk" name="otpchk" style={{ marginRight: 5, width: 18, height: 18 }} required />
                        All correspondence with the customer will be carried out on the above-mentioned email address unless changed in the future by the customer.
                    </label>

                    <div className="btndiv">
                        <Button style={{ margin: 10, background: "var(--secondary)", borderRadius: 10 }} onClick={() => history.goBack()}>
                            <ArrowLeft />
                            Back
                        </Button>
                        <Button type="submit" style={{ margin: 10, background: "var(--primary)", borderRadius: 10 }}>
                            Next
                            <ArrowRight />
                        </Button>
                    </div>
                </Row>
            </Grid>
        </Animatedpage>
    );
};

export default BasicDetails;
