

import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "./Data.Context";

const StepsContext = React.createContext({});

const StepsContextProvider = ({ children }) => {
    const [steps, setSteps] = useState([
        { completed: false, active: false, Name: "Account Details" }
        , { completed: false, active: false, Name: "Basic Details & OTP Verification" }
        , { completed: false, active: false, Name: "Individual Information" }
        , { completed: false, active: false, Name: "ZAKAT, FATCA & CRS Status" }
        , { completed: false, active: false, Name: "Value Added Services" }
        // , { completed: false, active: false, Name: "Liveness Test" }
        , { completed: false, active: false, Name: "Verification" }

    ]);
    const { data } = useContext(DataContext);
    useEffect(() => {

        var r = steps.findIndex(p => p.Name === "Remitter Details");
        if (r !== -1 && data.option.AccountType !== "Asaan Digital Remittance") {
            var arr = steps;
            arr.splice(r, 1);
            setSteps([...arr]);
        }
    }, [data])
    const AddSteps = (text, after) => {
        var arr = steps;
        var s = steps.findIndex(p => p.Name === after);

        arr.splice(s + 1, 0, { completed: false, active: false, Name: text });
        setSteps([...arr]);
    }
    const RemoveSteps = (text) => {
        var arr = steps;
        var s = arr.findIndex(p => p.Name === text);
        if (s !== -1) {
            arr.splice(s, 1);
            setSteps([...arr]);
        }
    }
    const setActive = (atab) => {
        var a = steps;
        a.forEach(p => p.active = false);
        var t = a.indexOf(a.filter(p => p.Name === atab)[0]);
        if (t > -1) {
            a[t].active = true;
        }
        setSteps([...a]);
    }
    return (
        <StepsContext.Provider value={{
            steps,
            setActive,
            AddSteps,
            RemoveSteps

        }}>
            {children}
        </StepsContext.Provider>
    )
};

export {
    StepsContext,
    StepsContextProvider
};